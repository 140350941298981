<template>
  <div class="elv-report-valuation-container">
    <Header
      ref="headerRef"
      type="VALUATION"
      :title="t('menus.valuation')"
      updated-on=""
      :show-regenerate-button="false"
      :show-regenerate="false"
    />
    <div v-if="hideHeaderOperating" class="elv-report-transactions-header-operating">
      <div v-if="showHeaderFrequency" class="elv-report-transactions-header-frequency" @click="onAdjustmentFrequency">
        <SvgIcon name="adjustment-frequency-icon" width="14" height="14" />
        <span>{{ t('title.adjustmentFrequency') }}</span>
      </div>
      <div
        v-loading="recalculateCostBasisLoading"
        :class="{ 'is-disabled': entityStore.entityDetail?.progress.recalculateAdjustmentStatus === 'DOING' }"
        class="elv-transactions-header-main-right__posting"
        @click="onRecalculateCostBasis"
      >
        <template v-if="entityStore.entityDetail?.progress.recalculateAdjustmentStatus !== 'DOING'">
          <SvgIcon name="Calculation" width="14" height="14" />
          <span>{{ t('button.recalculate') }}</span>
          <el-tooltip
            v-if="
              entityStore.entityDetail?.progress.recalculateAdjustmentStatus === 'FINISHED' &&
              dayjs(entityStore.entityDetail?.progress.postingDoneTime).isAfter(
                entityStore.entityDetail?.progress.recalculateAdjustmentDoneTime
              )
            "
            :show-arrow="true"
            effect="dark"
            placement="top"
            width="150"
            popper-class="elv-journal-posting-tips"
            overlay-class-name="elv-report-table-tips"
            :show-after="500"
          >
            <SvgIcon name="renew" width="14" height="14" fill="#1753EB" />
            <template #content>{{ t('message.pleaseRecalculateTheCost') }}</template>
          </el-tooltip>
        </template>
        <template v-else>
          <img src="@/assets/img/reports/sources-sync-loading.png" alt="" />
          <span>{{ t('button.recalculate') }}</span>
        </template>
      </div>
    </div>
    <HeaderTabs />

    <router-view></router-view>
  </div>
  <AdjustmentFrequencyDialog ref="adjustmentFrequencyDialogRef" />
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { ElMessage } from 'element-plus'
import Header from '../components/Header.vue'
import ValuationApi from '@/api/ValuationApi'
import HeaderTabs from './components/HeaderTabs.vue'
import { useEntityStore } from '@/stores/modules/entity'
import { useAccountStore } from '@/stores/modules/accounts'
import AdjustmentFrequencyDialog from './components/FutureOptions/AdjustmentFrequencyDialog.vue'

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const accountStore = useAccountStore()
const adjustmentFrequencyDialogRef = ref()

const recalculateCostBasisLoading = ref(false)

const entityId = computed(() => {
  return String(route.params.entityId)
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const hideHeaderOperating = computed(() => {
  return !['entity-valuation-assets', 'entity-valuation-price', 'entity-valuation-automation'].includes(
    String(route.name)
  )
})

const showHeaderFrequency = computed(() => {
  return ['entity-valuation-future', 'entity-valuation-options'].includes(String(route.name))
})

const onAdjustmentFrequency = () => {
  adjustmentFrequencyDialogRef.value.changeDialogStatus()
}

const onRecalculateCostBasis = async () => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.journal?.posting
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (entityStore.entityDetail?.progress.recalculateAdjustmentStatus !== 'DOING') {
    try {
      recalculateCostBasisLoading.value = true
      await ValuationApi.executeRecalculateAdjustment(entityId.value)
      entityStore.fetchEntityDetail(entityId.value)
      ElMessage.success(t('message.recalculateCostBasisStart'))
    } catch (error: any) {
      console.log(error)
      ElMessage.error(error.message)
    } finally {
      recalculateCostBasisLoading.value = false
    }
  }
}

watch(
  () => route,
  async () => {
    const valuationRoutes = ['entity-valuation-future', 'entity-valuation-options']
    if (valuationRoutes.includes(String(route.name))) {
      accountStore.fetchEntityAccountList(entityId.value, { withDeleted: true })
    }
  },
  { immediate: true, deep: true }
)
</script>

<style lang="scss">
.elv-report-valuation-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .elv-report-transactions-header-operating {
    position: absolute;
    top: 0px;
    right: 34px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    .elv-report-transactions-header-frequency {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-weight: 400;
      margin-right: 16px;
      cursor: pointer;

      svg {
        margin-right: 6px;
      }
    }

    .elv-transactions-header-main-right__posting {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      height: 30px;
      padding: 0 12px;
      box-sizing: border-box;
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border-radius: 22px;
      background: #f9fafb;
      cursor: pointer;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

      img {
        width: 14px;
        height: 14px;
        display: block;
        animation: loading-rotate 2s linear infinite;
      }

      svg {
        fill: #838d95;
      }

      &.is-disabled {
        cursor: not-allowed;
        color: #aaafb6;
        background-color: #edf0f3;
      }

      &:not(.is-disabled):hover,
      &:not(.is-disabled):focus,
      &:not(.is-disabled):active {
        color: #0e0f11;
        background-color: #edf0f3;

        svg {
          fill: #636b75;
        }
      }

      .el-loading-spinner {
        top: 100%;
        text-align: left;

        svg {
          width: 16px;
          height: 16px;
          margin-left: 12px;
        }
      }
    }
  }
}

.elv-valuation-record-table {
  --el-table-border-color: #d4dce5;
  --el-table-border: 1px solid #edf0f3;
  --el-fill-color-lighter: #f9fafb;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ced7e0;
  border-radius: 3px;
  border-bottom-width: 1px;

  .el-table__inner-wrapper {
    width: 100%;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  &.el-table--border {
    // border-bottom-color: #edf0f3;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  .el-table__body-wrapper {
    height: 616px;

    .el-scrollbar__view {
      margin-bottom: 0px !important;
      // .el-table__body tbody {
      //   tr {
      //     &:last-child td {
      //       border-bottom: 0px;
      //     }
      //   }
      // }
    }
  }

  .el-table__border-left-patch {
    width: 0;
  }

  .elv-valuation-record-table-header {
    background: #eef4fb;

    .elv-valuation-record-table-header__cell {
      background: #eef4fb !important;
      height: 36px;
      box-sizing: border-box;
      border-right-color: #e4e7eb;
      border-bottom-color: #ced7e0;
      padding: 0px;

      &:last-child {
        border-right: 0px;
      }

      &.is-center {
        .cell {
          justify-content: center;
        }
      }

      &.is-right .cell {
        justify-content: flex-end;
      }

      .cell {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #666d74;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-items: center;
      }
    }
  }

  .elv-valuation-record-table-row__cell {
    padding: 0;
    height: 42px;
    cursor: pointer;

    &:last-child {
      border-right: 0px;
    }

    &.is-right .cell {
      text-align: right;
    }

    &.is-center .cell {
      text-align: center;
    }

    &.is-number .cell {
      font-family: 'Barlow';
      font-weight: 500;
    }

    .cell {
      padding: 0 10px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #0e0f11;
      line-height: 16px;
    }

    &.elv-valuation-record-table-row__cell-index .cell {
      color: #aaafb6;
      font-family: 'Barlow';
      font-size: 11px;
      font-style: normal;
    }

    &.elv-valuation-record-table-row__cell-referenceNo .cell div {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow-wrap: break-word;
      overflow: hidden;
    }

    .elv-valuation-record-table-row__cell-activity {
      font-weight: 500;
    }

    &.el-table-column--selection {
      .cell {
        width: 100%;
        padding: 0px;
        align-items: center;
        justify-content: center;
      }
    }

    .elv-valuation-record-table-row__cell-desc {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      word-break: break-word;
    }

    .elv-valuation-record-cell__status {
      display: flex;
      height: 22px;
      width: fit-content;
      padding: 0px 8px;
      box-sizing: border-box;
      align-items: center;
      gap: 4px;
      border-radius: 14px;
      border: 1px solid #d0d4d9;
      background: #edf0f3;
      color: #636b75;

      svg {
        margin-right: 4px;
      }

      &.is-approved {
        border-color: #a9dc78;
        background: #f3fceb;
        color: #50970b;
      }
    }

    .elv-valuation-record-table-row__cell-button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        cursor: pointer;
        fill: #838d95;

        &.is-disabled {
          cursor: not-allowed !important;
          fill: #edf0f3 !important;
        }

        &:first-of-type {
          margin-right: 8px;
        }

        &:hover {
          fill: #1e2024;
        }
      }
    }
  }

  .el-table__footer-wrapper {
    .el-table__footer {
      height: 42px;

      .cell.elv-valuation-record-table-row__total {
        color: #636b75;
        font-family: 'Plus Jakarta Sans';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .cell.elv-valuation-record-table-row__amount {
        color: #0e0f11;
        font-family: 'Barlow';
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;

        p {
          color: #838d95;
          font-family: 'Barlow';
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: none;
        }
      }
    }
  }
}

.elv-valuation-record-list-pagination {
  margin-bottom: 20px;
}

.elv-valuation-record-pagination__sizes-popper.el-popper {
  transform: translateY(10.5px);
}
</style>
