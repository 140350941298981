<template>
  <el-dialog
    v-model="showDialog"
    width="539px"
    align-center
    :show-close="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-valuation-adjustment-frequency-dialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-valuation-adjustment-frequency-dialog-header__title">
        {{ t('title.adjustmentFrequency') }}
      </h4>
      <SvgIcon
        name="common-close"
        width="24"
        height="24"
        class="elv-confirm-info-header__close"
        @click="changeDialogStatus"
      />
    </template>
    <div v-loading="dialogLoading" class="elv-valuation-adjustment-frequency-dialog-content">
      <div class="elv-valuation-adjustment-frequency-dialog-content-check-type">
        <el-radio-group v-model="currentFrequency">
          <el-radio size="large" :value="'DAY'">{{ t('common.daily') }}</el-radio>
          <el-radio :value="'WEEK'">{{ t('common.weekly') }}</el-radio>
          <el-radio :value="'MONTH'">{{ t('common.Monthly') }}</el-radio>
        </el-radio-group>
      </div>
      <div class="elv-valuation-adjustment-frequency-dialog-content-check-message">
        <span></span>
        {{ t('message.adjustmentFrequencyInfo') }}
      </div>
    </div>
    <template #footer>
      <el-button
        type="primary"
        round
        class="elv-confirm-info-footer__confirm"
        :loading="saveLoading"
        @click="onConfirm"
        >{{ t('button.save') }}</el-button
      >
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { ElMessage } from 'element-plus'
import ValuationApi from '@/api/ValuationApi'
import { useEntityStore } from '@/stores/modules/entity'

const { t } = useI18n()
const entityStore = useEntityStore()

const showDialog = ref(false)
const saveLoading = ref(false)
const dialogLoading = ref(false)
const currentFrequency = ref('NONE')

const entityId = computed(() => {
  return String(entityStore.entityDetail?.entityId)
})

const adjustmentFrequency = computed(() => {
  return entityStore.entityDetail?.adjustmentFrequency || 'NONE'
})

/**
 * 切换调账频率设置弹窗显示状态
 */
const changeDialogStatus = () => {
  showDialog.value = !showDialog.value
  if (showDialog.value) {
    currentFrequency.value = adjustmentFrequency.value
  }
}

/**
 * 保存调账频率设置
 */
const onConfirm = async () => {
  saveLoading.value = true
  try {
    await ValuationApi.setAdjustmentFrequency(entityId.value, { adjustmentFrequency: currentFrequency.value })
    changeDialogStatus()
    ElMessage.success(t('message.success'))
  } catch (error: any) {
    console.error(error)
  } finally {
    saveLoading.value = false
  }
}

defineExpose({
  changeDialogStatus
})
</script>

<style lang="scss">
.elv-valuation-adjustment-frequency-dialog {
  height: auto;
  border-radius: 6px;
  box-shadow:
    0 2px 6px rgb(0 0 0 / 5%),
    0 0 1px rgb(0 0 0 / 30%);

  .el-dialog__body {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 24px !important;
    color: #636b75;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;

    .elv-valuation-adjustment-frequency-dialog-content {
      margin-bottom: 20px;

      .elv-valuation-adjustment-frequency-dialog-content-check-type {
        color: #1e2024;
        font-size: 14px;
        line-height: 28px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 28px;

        .el-radio-group {
          .el-radio__input {
            .el-radio__inner {
              width: 20px;
              height: 20px;
              font-size: 20px;
            }
          }

          .el-radio__label {
            padding-left: 6px;
          }

          .el-radio__input.is-checked .el-radio__inner {
            background: #1753eb;
            background-color: #1753eb;

            &::after {
              width: 8px;
              height: 8px;
              left: 51%;
              top: 51%;
            }
          }
        }
      }

      .elv-valuation-adjustment-frequency-dialog-content-check-message {
        padding: 0px 10px;
        display: flex;
        align-items: flex-start;

        span {
          display: flex;
          width: 4px;
          height: 4px;
          background-color: #636b75;
          border-radius: 50%;
          margin: 8px 6px 0 0;
        }
      }
    }
  }

  .el-dialog__header {
    display: flex;
    align-items: center;
    padding: 0px !important;
    border-bottom: 1px solid #edf0f3;

    .elv-valuation-adjustment-frequency-dialog-header__title {
      height: 54px;
      width: calc(100% - 48px);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #0e0f11;
      font-family: 'Plus Jakarta Sans';
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }

    .elv-confirm-info-header__close {
      cursor: pointer;
    }
  }

  .el-dialog__footer {
    padding-top: 0;
    padding-bottom: 20px;
    text-align: center;

    .el-button {
      width: 117px;
      height: 44px;
      padding: 14px 35px;
      font-family: 'Plus Jakarta Sans';
      font-size: 13px;
      font-weight: 500;
      line-height: 16px;
      color: #fff;
      background: #1753eb;
      border: 0;
      border-radius: 22px;
    }
  }
}
</style>
